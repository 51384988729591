//FLASH MESSAGES
var alerts = {
    timerId: null,

    init: function () {
        $('#alert-container').find('.alert-container-button').click(function (e) {
            alerts.hide();
            return false;
        });
    },

    show: function (header, message, type, timeout) {
        type = type || 'notice';
        timeout = timeout || false;

        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        var alertElem = $('#alert-container');
        alertElem.find('.alert-container-header').html(header);
        alertElem.find('.alert-container-message').html(message);
        alertElem.removeClass().addClass('alert-' + type).css({opacity: .1});

        var height = alertElem.height();
        alertElem.css({top: '-' + height + 'px'}).show().animate({top: '0px', opacity: .95});

        if (timeout) {
            this.timerId = window.setTimeout(alerts.hide, timeout);
        }
    },

    hide: function () {
        var alertElem = $('#alert-container');
        var height = alertElem.height();
        alertElem.animate({top: '-' + height + 'px', opacity: .1}).fadeOut();
    }
};

/* Show on scroll element */
function showOnScroll() {
    $('.cm-show-on-scroll').each(function (i) {

        var top_of_object = $(this).position().top;
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        /* If the object is completely visible in the window, fade it it */
        if (bottom_of_window > (top_of_object + 70)) {

            $('.container', this).animate({'opacity': '1'}, 1500)
            $(this).animate({
                'paddingTop'  : '50px',
                'marginBottom': '0px',
                'paddingLeft' : '0px',
                'paddingRight': '0px'
            }, 1250);


            $.each($('.cm-show-one-by-one', this), function (i, el) {

                if ($(el).parent().hasClass('cm-hide-row')) {
                } else {


                    setTimeout(function () {
                        $(el).animate({
                            'opacity': 1.0
                        }, 450);
                    }, 0 + ( i * 500 ));

                }
            });

        }

    });
};

var brandingFunctions = {

    init: function () {
        /* CM CHECKBOXES */
        $('.checkbox label input[type=checkbox]').each(function () {
            $(this).after('<span class="cm-check"><i class="fa fa-check"></i></span>');
        });

        /* CM RADIO BUTTONS */
        $('.radio label input[type=radio]').each(function () {
            $(this).after('<span class="cm-radio"><i class="fa fa-circle"></i></span>');
        });

        /* CM SELECT */
        $('SELECT.form-control').each(function () {
            $(this).addClass('selectpicker');
            if (/MSIE|Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
                $(this).data('mobile', 'true');
            }
            ;
        });


        /* VOICE SEARCH */
        var isChromium = window.chrome,
            vendorName = window.navigator.vendor;
        if (isChromium !== null && isChromium !== undefined && vendorName === "Google Inc.") {
            $('.cm-voice-search').show();
            $('.cm-voice-search').on('click', function () {

                var voiceFieldID = "#" + $(this).prev().attr('id');
                var voiceFieldMic = "#" + $(this).attr('id');
                var voiceFieldPlaceholder = $(this).prev().attr('placeholder');
                var voiceFieldSubmit = $(this).prev().data('submit');
                var voiceFieldPlaceholder = $(this).prev().attr('placeholder');
                $(voiceFieldMic).addClass('pulse');

                var language = $('html')[0].lang;

                var recognizer = new webkitSpeechRecognition();
                recognizer.lang = language;

                recognizer.onstart = function (event) {
                    var refillValue = $(voiceFieldID).data('refill');
                    $(voiceFieldID).attr('placeholder', refillValue);
                }

                recognizer.onend = function () {
                    $(voiceFieldMic).removeClass('pulse');
                    $(voiceFieldID).attr('placeholder', voiceFieldPlaceholder);
                };

                recognizer.onresult = function (event) {
                    if (event.results.length > 0) {

                        var result = event.results[event.results.length - 1];
                        if (result.isFinal) {
                            $(voiceFieldID).val(result[0].transcript);
                            $(voiceFieldMic).removeClass('pulse');

                            //CHECK FOR AUTO SUBMIT
                            if (voiceFieldSubmit == "yes") {
                                $(voiceFieldID).closest("form").submit();
                            }
                        }
                    }
                };

                recognizer.start();


            });
        }

        /* ADD ICONS TO TABLE */
        $(".cm-table").each(function (index) {

            //TR
            if ($(this).find('tr.active').length != 0) {
                $('tr.active td:first-child', this).prepend("<i class='icon icon-mc-arrow-right'></i>");
            }
            if ($(this).find('tr.success').length != 0) {
                $('tr.success td:first-child', this).prepend("<i class='icon icon-ok'></i>");
            }
            if ($(this).find('tr.warning').length != 0) {
                $('tr.warning td:first-child', this).prepend("<i class='icon icon-error'></i>");
            }
            if ($(this).find('tr.danger').length != 0) {
                $('tr.danger td:first-child', this).prepend("<i class='icon icon-cross'></i>");
            }
            if ($(this).find('tr.info').length != 0) {
                $('tr.info td:first-child', this).prepend("<i class='icon icon-question'></i>");
            }

            //TD
            if ($(this).find('td.active').length != 0) {
                $('td.active', this).prepend("<i class='icon icon-mc-arrow-right'></i>");
            }
            if ($(this).find('td.success').length != 0) {
                $('td.success', this).prepend("<i class='icon icon-ok'></i>");
            }
            if ($(this).find('td.warning').length != 0) {
                $('td.warning', this).prepend("<i class='icon icon-error'></i>");
            }
            if ($(this).find('td.danger').length != 0) {
                $('td.danger', this).prepend("<i class='icon icon-cross'></i>");
            }
            if ($(this).find('td.info').length != 0) {
                $('td.info', this).prepend("<i class='icon icon-question'></i>");
            }
        });

        /* SHOW MORE ROWS*/
        $(".cm-read-more").on('click', function (e) {

            $.each($('.cm-hide-row:first .cm-show-one-by-one'), function (i, el) {

                setTimeout(function () {
                    $(el).animate({
                        'opacity': 1.0
                    }, 450);
                }, 0 + ( i * 500 ));

            });
            $(".cm-hide-row:first").show();
            $(".cm-hide-row:first").removeClass("cm-hide-row");


            if ($(".cm-hide-row").length) {
            } else {
                $(this).hide();
            }

            e.preventDefault();
        });

        //SINGLE FILE UPLOAD

        //PROCESS ALL UPLOAD INPUT FIELDS

        function genUploadFields() {
            $(".upload").each(function () {
                var title = $(this).attr('data-title');
                var subtitle = $(this).attr('data-subtitle');
                var fileName = title + '<br /><span class="cm-text-grey">' + subtitle + '</span>';
                $(this).find('.filename').html(fileName);
            });
        };

        genUploadFields();

        $(function () {


            $(document).on("change", "input:file", function () {
                $(this).parent().focus();

                var fileId = $(this).attr("ID");
                var fileNames = "";


                for (var i = 0, len = document.getElementById(fileId).files.length; i < len; i++) {
                    fileNames = fileNames + document.getElementById(fileId).files[i].name + '<br />';
                }

                //var fileName = $(this).val().replace(/C:\\fakepath\\/i, '');
                if (fileNames) {
                    var resetText = $(this).parent().attr('data-reset');
                    var fileInfo = fileNames + '<a class="cm-remove-upload"><i class="icon icon-cross cm-text-grey"></i>' + resetText + '</a>';
                    $(this).next('.filename').html(fileInfo);
                    $(this).prev().removeClass('cm-text-grey');
                    $(this).prev().addClass('cm-text-blue');
                } else {
                    var title = $(this).parent().attr('data-title');
                    var subtitle = $(this).parent().attr('data-subtitle');
                    var fileInfo = title + '<br /><span class="cm-text-grey">' + subtitle + '</span>';
                    $(this).next('.filename').html(fileInfo);
                    $(this).prev().addClass('cm-text-grey');
                    $(this).prev().removeClass('cm-text-blue');
                }
            });

            //REMOVE SELECTED FILE
            $(document).on("click", ".upload .cm-remove-upload", function (event) {
                event.preventDefault();
                $(this).closest('.upload').find('input:file').val('');
                $(this).closest('.upload').find('.icon').removeClass('cm-text-blue');
                $(this).closest('.upload').find('.icon').addClass('cm-text-grey');
                var title = $(this).parent().parent().attr('data-title');
                var subtitle = $(this).parent().parent().attr('data-subtitle');
                var fileInfo = title + '<br /><span class="cm-text-grey">' + subtitle + '</span>';
                $(this).parent().html(fileInfo);
            });
        });

        //PARALLAX SPOTLIGHT
        $(window).scroll(function () {
            if ($(window).width() > 1024) {
                var scrolledY = $(window).scrollTop() * 0.92;
                $('.cm-spotlight-parallax').css('background-position', 'center ' + ((scrolledY)) + 'px');
            }
        });

        //MOBILE NAV FIX
        $(".navbar-collapse").css({maxHeight: $(window).height() - $(".navbar-header").height() + "px"});

        //MOBILE NAV CROSS ANIM
        $('.cm-navbar-toggle').click(function () {
            $('.cm-mobile-dropdown button').find('.fa').removeClass('fa-minus');
            $(this).toggleClass('cm-cross');
        });

        /* SCROLL MENU ON OFF */
        function updateHeader() {

            var top = 20;
            if ($(window).scrollTop() >= top) {
                $('.navbar').addClass('visible');
                $(".drop-panel").css('z-index', '1');
            } else {
                $('.navbar').removeClass('visible');
                if ($('.innav').hasClass('active')) {

                }
                else {
                    $(".drop-panel").css('z-index', '-999');
                }
            }
        };
        $(document).ready(updateHeader);
        $(window).resize(updateHeader);
        $(window).scroll(updateHeader);


        //BLOCKS EQUAL HEIGHT
        function blocksEqualHeight() {
            if ($(window).width() >= 768) {

                function numOrdA(a, b) {
                    return (a - b);
                }

                $(".row").each(function () {

                    var heights = [];

                    $(this).find(".cm-block-case").each(function (i) {

                        $(this).css("height", "initial");
                        heights.push($(this).height());
                        heights.sort(numOrdA);

                        if (heights.length === $(this).parent().parent().find(".cm-block-case").length) {
                            $(this).parent().parent().find(".cm-block-case").height(heights[heights.length - 1]);
                        }
                    });

                    var heightsHome = [];

                    $(this).find(".cm-block-case-home").each(function (i) {

                        $(this).find(".cm-block-content").css("height", "initial");
                        heightsHome.push($(this).find(".cm-block-content").height());
                        heightsHome.sort(numOrdA);

                        if (heightsHome.length === $(this).parent().parent().find(".cm-block-case-home").length) {
                            $(this).parent().parent().find(".cm-block-content").height(heightsHome[heightsHome.length - 1]);
                        }
                    });

                });

            }
        }

        //blocksEqualHeight();

        $(window).load(function () {
            blocksEqualHeight();
        });

        $(window).resize(function() {
            waitForFinalEvent(function() {
                blocksEqualHeight();
            }, 500, "uniqueId");
        });

        // FIRE EVENT AFTER RESIZE = waitForFinalEvent()
        var waitForFinalEvent = (function () {
            var timers = {};
            return function (callback, ms, uniqueId) {
                if (!uniqueId) {
                    uniqueId = "Don't call this twice without a uniqueId";
                }
                if (timers[uniqueId]) {
                    clearTimeout (timers[uniqueId]);
                }
                timers[uniqueId] = setTimeout(callback, ms);
            };
        })();
        // waitForFinalEvent();


        //FORM SUBMITS
        $("form").submit(function (event) {
            var btn = $(this).find('input[type=submit]');

            btn.attr('disabled', 'disabled');
            btn.val(btn.data('btn-onsubmit-txt'));

        });

        //ENABLE SUBMIT BUTTON
        $('.btn-loading-reset').click(function () {

            var $btn = $('.btn-loading');

            $btn.removeAttr('disabled');
            $btn.removeAttr("style");
            $btn.attr('value', $btn.data('btn-orignal-txt'));
        });

        //TABS
        $('#myTab a').click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });


        //POPOVER/TOOLTIP FIX FOR AJAX CALLED CONTENT
        $('body').tooltip({
            selector: '[rel="tooltip"]'
        });

        $('body').popover({
            selector: '[rel="popover"]',
            trigger : 'focus'
        });

        $('html .popoverHover').popover({
            trigger: 'hover'
        });


        // SEARCH AND LANGUAGE PICKER
        $(".innav").click(function () {

            var panelId = $(this).attr('id');
            var top = 20;

            if ($(this).hasClass('active')) {
                $(".navbar").removeClass("active");
                $("BODY").removeClass("active");

                if ($(window).scrollTop() >= top) {
                    setTimeout(function () {
                        $(".drop-panel").css('z-index', '-999');
                    }, 300);
                }
                else {
                    $(".drop-panel").css('z-index', '-999');
                }
            }
            else {
                $('.drop-panel .toggle').hide();
                $('.drop-panel #' + panelId).show();

                if ($('.innav').hasClass('active')) {
                }
                else {
                    $(".navbar").addClass("active");
                    $("BODY").addClass("active");

                    if ($(window).scrollTop() >= top) {
                        $(".drop-panel").css('z-index', '1');
                    }
                    else {
                        setTimeout(function () {
                            $(".drop-panel").css('z-index', '1');
                        }, 300);
                    }
                }
            }

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }
            else {
                $('.innav').removeClass('active');
                $(this).addClass('active');
            }

        });


        // TOGGLE MOBILE MENU SUBMENU PREFIX
        $('.cm-mobile-dropdown button').click(function () {
            if ($(this).find('.fa').hasClass('fa-minus')) {
                $(this).find('.fa').removeClass('fa-minus');
            } else {
                $('.cm-mobile-dropdown button').find('.fa').removeClass('fa-minus');
                $(this).find('.fa').addClass('fa-minus');
            }

        });

        // TOGGLE PUBLIC CLASS DEFINITION
        // ==============================

        var Toggle = function (element, options) {
            this.$element = $(element)
            this.options = $.extend({}, this.defaults(), options)
            this.render()
        }

        Toggle.VERSION = '2.2.0'

        Toggle.DEFAULTS = {
            on      : 'On',
            off     : 'Off',
            onstyle : 'primary',
            offstyle: 'default',
            size    : 'normal',
            style   : '',
            width   : null,
            height  : null
        }

        Toggle.prototype.defaults = function () {
            return {
                on      : this.$element.attr('data-on') || Toggle.DEFAULTS.on,
                off     : this.$element.attr('data-off') || Toggle.DEFAULTS.off,
                onstyle : this.$element.attr('data-onstyle') || Toggle.DEFAULTS.onstyle,
                offstyle: this.$element.attr('data-offstyle') || Toggle.DEFAULTS.offstyle,
                size    : this.$element.attr('data-size') || Toggle.DEFAULTS.size,
                style   : this.$element.attr('data-style') || Toggle.DEFAULTS.style,
                width   : this.$element.attr('data-width') || Toggle.DEFAULTS.width,
                height  : this.$element.attr('data-height') || Toggle.DEFAULTS.height
            }
        }

        Toggle.prototype.render = function () {
            this._onstyle = 'btn-' + this.options.onstyle
            this._offstyle = 'btn-' + this.options.offstyle
            var size = this.options.size === 'large' ? 'btn-lg'
                : this.options.size === 'small' ? 'btn-sm'
                : this.options.size === 'mini' ? 'btn-xs'
                : ''
            var $toggleOn = $('<label class="btn">').html(this.options.on)
                .addClass(this._onstyle + ' ' + size)
            var $toggleOff = $('<label class="btn">').html(this.options.off)
                .addClass(this._offstyle + ' ' + size + ' active')
            var $toggleHandle = $('<span class="toggle-handle btn btn-default">')
                .addClass(size)
            var $toggleGroup = $('<div class="toggle-group">')
                .append($toggleOn, $toggleOff, $toggleHandle)
            var $toggle = $('<div class="toggle btn" data-toggle="toggle">')
                .addClass(this.$element.prop('checked') ? this._onstyle : this._offstyle + ' off')
                .addClass(size).addClass(this.options.style)

            this.$element.wrap($toggle)
            $.extend(this, {
                $toggle     : this.$element.parent(),
                $toggleOn   : $toggleOn,
                $toggleOff  : $toggleOff,
                $toggleGroup: $toggleGroup
            })
            this.$toggle.append($toggleGroup)

            var width = this.options.width || Math.max($toggleOn.outerWidth(), $toggleOff.outerWidth()) + ($toggleHandle.outerWidth() / 2)
            var height = this.options.height || Math.max($toggleOn.outerHeight(), $toggleOff.outerHeight())
            $toggleOn.addClass('toggle-on')
            $toggleOff.addClass('toggle-off')
            this.$toggle.css({width: width, height: height})
            if (this.options.height) {
                $toggleOn.css('line-height', $toggleOn.height() + 'px')
                $toggleOff.css('line-height', $toggleOff.height() + 'px')
            }
            this.update(true)
            this.trigger(true)
        }

        Toggle.prototype.toggle = function () {
            if (this.$element.prop('checked')) this.off()
            else this.on()
        }

        Toggle.prototype.on = function (silent) {
            if (this.$element.prop('disabled')) return false
            this.$toggle.removeClass(this._offstyle + ' off').addClass(this._onstyle)
            this.$element.prop('checked', true)
            if (!silent) this.trigger()
        }

        Toggle.prototype.off = function (silent) {
            if (this.$element.prop('disabled')) return false
            this.$toggle.removeClass(this._onstyle).addClass(this._offstyle + ' off')
            this.$element.prop('checked', false)
            if (!silent) this.trigger()
        }

        Toggle.prototype.enable = function () {
            this.$toggle.removeAttr('disabled')
            this.$element.prop('disabled', false)
        }

        Toggle.prototype.disable = function () {
            this.$toggle.attr('disabled', 'disabled')
            this.$element.prop('disabled', true)
        }

        Toggle.prototype.update = function (silent) {
            if (this.$element.prop('disabled')) this.disable()
            else this.enable()
            if (this.$element.prop('checked')) this.on(silent)
            else this.off(silent)
        }

        Toggle.prototype.trigger = function (silent) {
            this.$element.off('change.bs.toggle')
            if (!silent) this.$element.change()
            this.$element.on('change.bs.toggle', $.proxy(function () {
                this.update()
            }, this))
        }

        Toggle.prototype.destroy = function () {
            this.$element.off('change.bs.toggle')
            this.$toggleGroup.remove()
            this.$element.removeData('bs.toggle')
            this.$element.unwrap()
        }

        // TOGGLE PLUGIN DEFINITION
        // ========================

        function Plugin(option) {
            return this.each(function () {
                var $this = $(this)
                var data = $this.data('bs.toggle')
                var options = typeof option == 'object' && option

                if (!data) $this.data('bs.toggle', (data = new Toggle(this, options)))
                if (typeof option == 'string' && data[option]) data[option]()
            })
        }

        var old = $.fn.bootstrapToggle

        $.fn.bootstrapToggle = Plugin
        $.fn.bootstrapToggle.Constructor = Toggle

        // TOGGLE NO CONFLICT
        // ==================

        $.fn.toggle.noConflict = function () {
            $.fn.bootstrapToggle = old
            return this
        }

        // TOGGLE DATA-API
        // ===============

        $(function () {
            $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle()
        })

        $(document).on('click.bs.toggle', 'div[data-toggle^=toggle]', function (e) {
            var $checkbox = $(this).find('input[type=checkbox]')
            $checkbox.bootstrapToggle('toggle')
            e.preventDefault()
        });

        // Loading navbar animation
        $('form').on('submit', function () {
            $('body').attr('data-loading-perc', 'free');
        });

        //STOP LOADING ANIMATION
        $('.btn-stop-loading').click(function () {

            var $btn = $('.btn-start-loading');

            $btn.removeAttr('disabled');
            $btn.removeAttr("style");
            $('body').removeAttr('data-loading-perc');
            $btn.attr('value', $btn.data('btn-orignal-txt'));
        });

    }
};

$(document).ready(showOnScroll);
$(window).scroll(showOnScroll);


$(document).ready(function () {

    if (typeof thirdpartyEnabled == 'undefined') {
        brandingFunctions.init();
    }
});